#btn__bg {
    background: linear-gradient(107.8deg, #625072 0%, #625072 105%);
    transition: .5s;
    position: relative;
}


#btn__bg:hover::before {
    left: 120%;
}

#btn__bg:before {
    content: '';
    background: #ffffff4d;
    top: 0;
    left: -100%;
    position: absolute;
    width: 11%;
    height: 150%;
    transform: skewX(15deg);
    transition: .8s;
    filter: blur(10px);
}
