/* LandingPage.module.css */


  
  .flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    height: 100%;
  }
  
  .innerContainer {
    height: 100%;
    min-height: 95vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0;
    padding-left: 15%;
    padding-right: 15%;
    display: flex;
    position: relative;
  }
  
  .headerCopyClipped {
    width: 1350px;
    background-image: linear-gradient(181.7deg, #625072, #db706e);
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 0;
    padding-bottom: 10px;
    font-family: Graphik, sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;

  }
  .clipped{
    margin-top: 2vh;
    -webkit-text-fill-color: transparent !important;
    -webkit-background-clip: text !important;
    text-align: center;
  }
  
  .headerClipped {
    width: 89vw;
    background-image: linear-gradient(181.7deg, #625072, #625072);
    margin-top: 5px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-family: Graphik, sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 1.1;

  }
  
  .text {
    z-index: 1;
    width: 1000px;
    color: #7b7b7b;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -10px;
    font-family: Graphik, sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    position: relative;
  }
  
  .test {
    height: 26vh;
    margin: 78px 0px -100px 0px;
  }
  
  .imgForLogo {
    width: 26vw;
    height: 8vh;
  }
  
  #btn__bg {
    background: linear-gradient(107.8deg, #625072 0%, #625072 105%);
    transition: 0.5s;
    position: relative;
  }
  
  .clickButton {
    z-index: 0;
    width: 223px;
    height: 63px;
    cursor: pointer;
    background-image: linear-gradient(152.8deg, #0035f3, #00b9e7);
    border-radius: 75px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
    padding: 8px 0px 6px;
    font-family: Graphik, sans-serif;
    font-size: 24px;
    display: flex;
    position: relative;
  }
  
  #btn__bg:hover::before {
    left: 120%;
  }
  
  #btn__bg:before {
    content: "";
    background: #ffffff4d;
    top: 0;
    left: -100%;
    position: absolute;
    width: 11%;
    height: 150%;
    transform: skewX(15deg);
    transition: 0.8s;
    filter: blur(10px);
  }
  
  a:active,
  a:hover {
    outline: 0;
  }
  
  a {
    color: #115cef;
    padding: 0;
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    text-decoration: none;
  }
  
  .linkBlock {
    z-index: 10000;
    width: 250px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    position: absolute;
  }
  
  .wInlineBlock {
    max-width: 100%;
    display: inline-block;
  }
  
  .buttonHeader {
    z-index: 0;
    color: #fff;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
    position: relative;
  }
  
  h2 {
    letter-spacing: -0.01em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: 42px;
    font-weight: 600;
    line-height: 1.2em;
  }
  
  .buttonSubheading {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
  }
  
  h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5em;
  }
  
  .loginLink {
    color: #515152;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
    text-decoration: underline;

  }
  
  @media screen and (max-width: 1022px) {
    .mainDivClone1 {
      margin-left: 147px;
    }
  }
  
  @media screen and (max-width: 713px) {
    .mainDivClone1 {
      margin-left: 207px;
    }
  }
  
  @media screen and (max-width: 608px) {
    .mainDivClone1 {
      margin-left: 207px;
    }
  
    .headerClipped {
      font-size: 27px;
    }
  
    .text {
      margin-left: 111px;
    }
  }
  
  @media screen and (max-width: 480px) {
    body {
      overflow: hidden;
    }
    #root {
      height: 100vh;
      overflow: hidden;
    }
    .mainDivClone1 {
      margin-left: 0px;
    }
    .headerCopyClipped {
      font-weight: 100;
      font-size: 35px;
    }
    .headerClipped {
      font-size: 35px;
      width: 75vw;
    }
    .text {
      margin-left: 0px;
      font-size: 14px;
      width: 91vw;
    }
    .imgForLogo {
      width: 27vw;
      height: 4vh;
    }
    .clickButton {
      width: 158px;
      height: 60px;
      margin-top: -32px;
    }
    .loginLink {
      margin-top: 25px;
    }
  }
  