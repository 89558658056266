@keyframes borderImageAnimation {
    from {
      border-width: 0;
    }
    to {
      border-width: 4px; /* Adjust the border width as needed */
    }
  }



.classforborderonlyDiv{
    padding:15px 15px;
 
    width:34vw;
    border: 1px solid transparent;
  border-image-source: linear-gradient(115.01deg, #E17E60 0%, #9152FC 100%);
  border-image-slice: 1;
  border-radius: 5px;

  /* animation: borderImageAnimation 0.3 ease-out; */
  transform: scale(1.04);



}



@keyframes textSlideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }



  .classforborderonlyDiv h1,
.classforborderonlyDiv p {
  /* animation: textSlideIn 0.5s ease-out ; */
  
}

  /* .hpforanimation{

    animation: textSlideIn 0.3s ease-out;
  } */

