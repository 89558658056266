/* Overlay for clicking outside */
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 254, 254,0.5);
    z-index: 999;
  }
  
  /* Sidebar styles */
  .sidebar {
    position: fixed;
    top: 0;
    right: -300px; /* Hidden on the right */
    width: 300px;
    height: 100vh;
    background-color: rgba(0,0,0,1);
    color: white;
    transition: right 0.3s ease; /* Smooth slide-in */
    z-index: 1111;
    padding: 20px;
  }
  
  .sidebar.open {
    right: -18vw; /* Sidebar visible */
  }
  
  .close-btn {
    position: absolute;
    top: 20px;
    right: 28vw;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .open-sidebar-btn {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    cursor: pointer;
  }
  

