.modal-content {
  padding: 49px 22px !important ;
  height: 69vh;
}

.button {
  margin: 23px 20vw;
  padding: 9px 22px;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-top: -58px;
  margin-bottom: 47px;
}


.custom-section {
  max-width: 465px;
  width: 100%;
  margin-top: 87px;
  padding: 50px 40px;
  border-radius: 6px;
  box-shadow: 0 0 4px #afafaf;
  /* height: 92vh; */
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.custom-section .signup-description {
  margin-top: 20px;
  font-weight: bolder;
  text-transform: capitalize;
  line-height: 30px;

 
}



.font-bold {
  font-weight: 700;
}

.text-sm,
.text-tremor-default {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.custom-section .signup-basic-form {
  margin-top: 10px;
}
.signup-form .custom-form-item {
  margin-bottom: 10px;
}
.signup-form .custom-form-item label {
  display: block;
  font-size: 0.8125rem;
  margin-bottom: 5px;
  margin-left: 4px;
}

.signup-form .custom-form-item input:not(.PhoneInputInput),
.signup-form .custom-form-item .PhoneInput {
  display: block;
  box-sizing: inherit;
  margin: 0;
  padding: 0.625rem 1rem;
  background-color: #fff;
  color: #000;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: 100%;
  aspect-ratio: unset;
  accent-color: rgb(16, 63, 239);
  font-family: inherit;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.25;
  border-radius: 0.375rem;
  border: 1px solid rgba(0, 0, 0, 0.16);
  -webkit-font-smoothing: auto !important;
}

.signup-form .err-msg {
  color: #e42f2f;
  font-size: 0.8125rem;
  margin-top: 4px;
  margin-left: 4px;
}

.signup-form .custom-form-item {
  margin-bottom: 14px;
}

.signup-form .agree_tnc {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.signup-form .btn-submit {
  width: 100%;
  padding: 10px;
  background-color: #625072;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 700;
  border-radius: 4px;
  border: none;
  /* cursor: pointer; */
  margin-top: 30px;
  transition: all 0.3s;
}
.img-set{
    margin-left: 94px;
}


@media screen and (max-width:457px){

 .custom-section .signup-description{
/* margin-left:11px; */
 } 

 .img-set{
  margin-left:50px;
 }
}

span[aria-label]:before{
  content: "" !important;
  position: absolute !important;
  z-index: 1 !important;
  background: rgba(0, green, blue, alpha) !important;
  top: 0px !important;
  bottom: 0px !important;
}

.backgroundcolorofalert{
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* widht: 100vw; */
  left: 0px;
  right: 0px;
  background: rgba(0,0,0,0.5);
}





.bgforsignuppage{
  background: url("/public/secondpage/signuppage/Frame180.svg");
}